import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';

// utils
import { whiteAt98, fonts } from '@lumoslabs/react_lux';
import i18n from '../../../utils/i18n';
import ScrollReveal from '../../utils/ScrollRevealWrapper';

// styles
import * as layout from '../../../styles/helpers/layout';

// images
import first from './images/first_avatar.svg';
import second from './images/second_avatar.svg';

const imageMap = {
  first,
  second
};

function reviewerImage(reviewerName) {
  return imageMap[reviewerName];
}

const styles = StyleSheet.create({
  card: {
    ...layout.alignLeft,
    ...layout.centerVertical,
    flexDirection: 'column',
    padding: '40px 22px',
    boxSizing: 'border-box',
    backgroundColor: whiteAt98,
    borderRadius: 8,
    boxShadow: '0 4px 9px 0 rgba(0,0,0,0.08), 0 1px 2px 0 rgba(0,0,0,0.04)',
    marginTop: 8,
    [layout.mediaQuery.minWidth.tablet]: {
      marginTop: 0
    }
  },
  avatarContainer: {
    ...layout.centerVertical,
    ...layout.alignLeft,
    width: '100%',
    paddingLeft: 30,
    marginTop: 20,
    [layout.mediaQuery.maxWidth.tablet]: {
      marginTop: 12
    },
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      paddingLeft: 20
    }
  },
  avatar: {
    display: 'inline-block',
    width: 50,
    paddingRight: 10,
    [layout.mediaQuery.maxWidth.tablet]: {
      paddingRight: 6,
      width: 36
    }
  },
  testimonialContent: {
    ...fonts.p,
    maxWidth: '90%'
  },
  testimonialDetails: {
    ...fonts.p,
    display: 'inline-block',
    margin: '0 0 0 10px'
  }
});

function TestimonialCard({ reviewerName }) {
  return (
    <ScrollReveal animation="slideUp">
      <div className={css(styles.card)}>
        <p className={css(styles.testimonialContent)}>
          {i18n.t(`homePage.TestimonialCardGroup.${reviewerName}Testimonial.content`)}
        </p>
        <div className={css(styles.avatarContainer)}>
          <img
            src={reviewerImage(reviewerName)}
            alt={i18n.t(`homePage.TestimonialCardGroup.${reviewerName}Testimonial.avatarImageAlt`)}
            className={css(styles.avatar)}
          />
          <p className={css(styles.testimonialDetails)}>
            {i18n.t(`homePage.TestimonialCardGroup.${reviewerName}Testimonial.name`)}
            {', '}
            {i18n.t(`homePage.TestimonialCardGroup.${reviewerName}Testimonial.location`)}
          </p>
        </div>
      </div>
    </ScrollReveal>
  );
}

TestimonialCard.propTypes = {
  reviewerName: PropTypes.string.isRequired
};

export default TestimonialCard;
