import React from 'react';
import PropTypes from 'prop-types';
import { AggregateRating, Author, JSONLD, Generic, GenericCollection, Review } from 'react-structured-data';

import appIcon from './images/app_icon.svg';

function SchemaDotOrg({ children }) {
  return (
    <JSONLD dangerouslyExposeHtml>
      <Generic
        type="softwareApplication"
        jsonldtype="SoftwareApplication"
        schema={{
          applicationCategory: 'http://schema.org/Game',
          operatingSystem: 'iOS, ANDROID',
          name: 'Lumosity: Daily Brain Games',
          isAccessibleForFree: 'Yes',
          copyrightHolder: 'Lumos Labs (Lumosity)',
          description:
            'Just a few minutes a day for 3 brain games — that’s all it takes. Every day, you get a fresh workout to keep you challenged.',
          sameAs: [
            'https://twitter.com/Lumosity',
            'https://www.linkedin.com/company/lumos-labs-lumosity-/',
            'https://www.facebook.com/Lumosity/',
            'https://en.wikipedia.org/wiki/Lumosity',
            'https://www.wikidata.org/wiki/Q6703384',
            'https://itunes.apple.com/us/app/lumosity-daily-brain-games/id577232024?mt=8',
            'https://play.google.com/store/apps/details?id=com.lumoslabs.lumosity&hl=en_US'
          ]
        }}
      >
        {children}
        <Generic
          type="offers"
          jsonldtype="Offers"
          schema={{
            '@type': 'Offer',
            price: '0.00',
            priceCurrency: 'USD'
          }}
        />
        <AggregateRating ratingValue={4.7} bestRating={5} worstRating={1} reviewCount={52287} ratingCount={52287} />
        <GenericCollection type="review">
          <Review
            name="I’ve learned a lot from Lumosity"
            reviewBody="I’ve learned a lot from Lumosity about how my mind works depending on how much sleep I’ve had, how much exercise I get, and how much my mood affects my mental functions."
            datePublished="11/22/2018"
          >
            <Author name="Judy" />
          </Review>
          <Review
            name="I’ve learned a lot from Lumosity"
            reviewBody="My maths efficiency has really improved so much since I have been playing every day. I used to think I was awful at maths but it turns out I am not too shabby after all!"
            datePublished="11/22/2018"
          >
            <Author name="Lynette" />
          </Review>
        </GenericCollection>
        <Generic type="image" jsonldtype="Image" schema={{ '@type': 'ImageObject', thumbnailUrl: appIcon }} />
      </Generic>
    </JSONLD>
  );
}

SchemaDotOrg.propTypes = {
  children: PropTypes.node
};

SchemaDotOrg.defaultProps = {
  children: null
};

export default SchemaDotOrg;
