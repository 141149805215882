import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { layout, mediaQuery, blackAt62, fontStyles } from '@lumoslabs/react_lux';

import i18n from 'utils/i18n';

import Ratings from './images/ratings.svg';

import TestimonialCard from './TestimonialCard';

const defaultStyles = StyleSheet.create({
  container: {
    [mediaQuery.maxWidth.tablet]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  header: {
    textAlign: 'center'
  },
  ratingsImageColumn: {
    margin: '20px 0',
    [mediaQuery.maxWidth.mobileLarge]: {
      flexDirection: 'column-reverse',
      margin: '10px 0 0'
    }
  },
  ratingsImage: {
    display: 'inline-block',
    width: 153,
    [mediaQuery.maxWidth.tablet]: {
      width: 132
    }
  },
  ratingText: {
    display: 'inline-block',
    color: blackAt62,
    marginLeft: 8,
    [mediaQuery.maxWidth.mobileLarge]: {
      flexDirection: 'column-reverse',
      marginLeft: 0,
      marginBottom: 10
    }
  },
  cardGroup: {
    marginTop: 42,
    [mediaQuery.maxWidth.tablet]: {
      marginTop: 28
    }
  }
});

function TestimonialCardGroup({ styles }) {
  return (
    <Container className={css(defaultStyles.container, layout.defaultContainerProperties, styles)}>
      <Row className={css(layout.centerHorizontal)}>
        <Col xs="12">
          <h3 className={css(defaultStyles.header, fontStyles.h3)}>{i18n.t('homePage.TestimonialCardGroup.header')}</h3>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className={css(defaultStyles.ratingsImageColumn, layout.centerHorizontal, layout.centerVertical)}>
          <img
            src={Ratings}
            className={css(defaultStyles.ratingsImage)}
            alt={i18n.t('homePage.TestimonialCardGroup.ratingsImgAlt')}
          />
          <p className={css(fontStyles.p, defaultStyles.ratingText)}>
            {i18n.t('homePage.TestimonialCardGroup.appStoreRating')}
          </p>
        </Col>
      </Row>
      <Row className={css(defaultStyles.cardGroup, layout.centerHorizontal, layout.centerVertical)}>
        <Col xs="12" md="6" className={css(defaultStyles.card)}>
          <TestimonialCard reviewerName="first" />
        </Col>
        <Col xs="12" md="6" className={css(defaultStyles.card)}>
          <TestimonialCard reviewerName="second" />
        </Col>
      </Row>
    </Container>
  );
}

TestimonialCardGroup.propTypes = {
  styles: PropTypes.arrayOf(PropTypes.object)
};

TestimonialCardGroup.defaultProps = {
  styles: []
};

export default TestimonialCardGroup;
